import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Col, Row,Spinner  } from 'react-bootstrap';
import './BandListComponent.css';
import GetUserInfo from '../GetUserInfo';

const BandListComponent = ({Organizer_ID}) => {
    const [bands, setBands] = useState([]);
    const [selectedBand, setSelectedBand] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showProposalModal, setShowProposalModal] = useState(false);
    const [searchParams, setSearchParams] = useState({ limit: 20, page: 1 });
    const [formValues, setFormValues] = useState({
        bandName: '',
        zipCode: '',
        city: '',
        state: ''
    });
    const [proposalPrice, setProposalPrice] = useState('');
    const [proposalMessage, setProposalMessage] = useState('');
    const [userID, setUserID] = useState(''); // Replace with actual user ID
    const [selectedProposal, setSelectedProposal] = useState(null);
    const [gigs, setGigs] = useState([]); // State for gigs
    const [selectedGig, setSelectedGig] = useState(''); // State for selected gig
    const [loading, setLoading] = useState(false); // Loading state

    useEffect(() => {
        fetchBands();
    }, [searchParams]);

    useEffect(() => {
        if (selectedProposal) {
            fetchGigsForBand(Organizer_ID);
        }
    }, [selectedProposal]);

    const fetchBands = async () => {
        setLoading(true); // Start loading
        try {
            const params = new URLSearchParams({
                limit: searchParams.limit.toString(),
                page: searchParams.page.toString(),
                ...(formValues.bandName && { Band_name: formValues.bandName }),
                ...(formValues.zipCode && { Band_zipcode: formValues.zipCode }),
                ...(formValues.city && { Band_city: formValues.city }),
                ...(formValues.state && { Band_state: formValues.state }),
            }).toString();

            const response = await fetch(`http://34.232.96.254/BandUserListing.php?${params}`);
            const data = await response.json();
            setBands(data.bands);
            setLoading(false); // End loading
        } catch (error) {
            //setLoading(false); // End loading
            console.error('Error fetching bands:', error);
        }
    };

    

    const fetchGigsForBand = async (organizerID) => {
        try {
            const response = await fetch(`http://34.232.96.254/GetGigsByOrganizerID.php?OrganizerID=${organizerID}`);
            const data = await response.json();
            setGigs(data || []);
        } catch (error) {
            console.error('Error fetching gigs:', error);
        }
    };

    const handleViewClick = (band) => {
        setSelectedBand(band);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedBand(null);
    };

    const handleShowProposalModal = (band) => {
        setSelectedProposal(band);
        setShowProposalModal(true);
    };

    const handleCloseProposalModal = () => {
        setShowProposalModal(false);
        setSelectedProposal(null);
        setProposalPrice('');
        setProposalMessage('');
        setSelectedGig(''); // Clear selected gig
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevState => ({ ...prevState, [name]: value }));
    };

    const handleProposalInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'proposalPrice') {
            setProposalPrice(value);
        } else if (name === 'proposalMessage') {
            setProposalMessage(value);
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setSearchParams(prevState => ({ ...prevState, page: 1 })); // Reset to page 1 on new search
    };

    const formatDate = (date) => {
        const pad = (num) => (num < 10 ? '0' + num : num);
    
        const day = pad(date.getDate());
        const month = pad(date.getMonth() + 1); // Months are zero-based
        const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());
    
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    };

    const handleSendProposal = () => {
        // Validate that the necessary data is available
        if (!proposalPrice || !proposalMessage || !selectedGig) {
            alert("Please fill in all fields before sending the proposal.");
            return;
        }
      console.log("Selected Gig: "+JSON.stringify(selectedGig));
      //console.log("Gigs:"+JSON.stringify(gigs));
        // Find the selected gig details
        const gig = gigs.find(g => g.Gig_id.toString()  === selectedGig);
        console.log("Gig: "+JSON.stringify(gig));

        if (!gig) {
            alert("Selected gig is not valid.");
            return;
        }

        const organizer_email = GetUserInfo.GetOrganizerEmailFromID(userID);

        console.log("organizer email: "+organizer_email);

        // Gather the data to send
        const proposalData = {            
            Organizer_id: Organizer_ID, // Organizer ID (if needed)
            Proposed_Price: proposalPrice, // The price entered by the organizer
            Proposed_Message: proposalMessage, // The message entered by the organizer
            Gig_id: gig.Gig_id,
            Band_id: gig.Band_id,
            Gig_Title: gig.Gig_Title,
            Venue_id: gig.VenueID,
            Venue: gig.Venue,
            Proposal_Status:"Proposal",
            Date_Registered:formatDate(new Date()),
            Organizer_email:organizer_email,
        };

        // Construct the URL with the correct parameters
        const url = `http://34.232.96.254/OrganizerPropose.php?Gig_id=${proposalData.Gig_id}&Organizer_id=${proposalData.Organizer_id}&Band_id=${proposalData.Band_id}&Venue=${encodeURIComponent(proposalData.Venue)}&Gig_Title=${encodeURIComponent(proposalData.Gig_Title)}&Proposed_Price=${proposalData.Proposed_Price}&Venue_id=${proposalData.Venue_id}&Proposed_Message=${encodeURIComponent(proposalData.Proposed_Message)}&Proposal_Status=${encodeURIComponent(proposalData.Proposal_Status)}&Date_Registered=${encodeURIComponent(proposalData.Date_Registered)}&Organizer_email=${encodeURIComponent(proposalData.Organizer_email)}`;

        // Make the POST request to the OrganizePropose.php endpoint
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {  // Check for 'status' instead of 'success'
                alert('Proposal sent successfully');
                handleCloseProposalModal();
            } else {
                alert('Failed to send proposal: ' + data.message);
            }
        })
        .catch(error => {
            console.error('Error sending proposal:', error);
            alert('An error occurred while sending the proposal.');
        });
    };

    return (
        <div className="band-list-container">
            <Form onSubmit={handleSearchSubmit} className="bandsearch-form">
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Band Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="bandName"
                            value={formValues.bandName}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                            type="text"
                            name="zipCode"
                            value={formValues.zipCode}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            name="city"
                            value={formValues.city}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                            type="text"
                            name="state"
                            value={formValues.state}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Row>
                <Button variant="primary" type="submit" style={{ marginTop: '10px' }}>Search</Button>
            </Form>
            {loading ? (
    <div className="loading-indicator">
        <Spinner animation="border" />
    </div>
) : (
    <div className="band-list">
        {bands.map(band => (
            <div key={band.Band_ID} className="band-card">
                <strong>{band.Band_name}</strong>
                {band.Band_video ? (
  <div style={{ width: "1080px", height: "720px", display: "flex", justifyContent: "center", alignItems: "center", margin: "0 auto" }}>
    <video width="100%" height="100%" controls>
      <source src={`data:video/mp4;base64,${band.Band_video}`} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
) : (
  <div style={{ width: "1080px", height: "720px", backgroundColor: "#f0f0f0", display: "flex", justifyContent: "center", alignItems: "center", margin: "0 auto" }}>
    <h3>No video available</h3>
  </div>
)}



                {band.Band_audio && (
                    <div>
                        <h3>Audio Preview</h3>
                        <audio controls>
                            <source src={band.Band_audio} type="audio/mpeg" />
                            <source src={band.Band_audio} type="audio/wav" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                )}
                <p>{band.Band_zipcode}</p>
                <p>{band.Band_city}</p>
                <p>{band.Band_state}</p>
                <p>{band.Band_address}</p>
                <Button variant="primary" onClick={() => handleViewClick(band)}>View</Button>
                <Button variant="secondary" style={{ marginLeft: '10px' }} onClick={() => handleShowProposalModal(band)}>Send Proposal</Button>
            </div>
        ))}
    </div>
)}

       
            {selectedBand && (
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedBand.Band_name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong>Genre:</strong> {selectedBand.Band_genre || 'N/A'}</p>
                        <p><strong>Zip Code:</strong> {selectedBand.Band_zipcode || 'N/A'}</p>
                        <p><strong>City:</strong> {selectedBand.Band_city || 'N/A'}</p>
                        <p><strong>State:</strong> {selectedBand.Band_state || 'N/A'}</p>
                        <p><strong>Address:</strong> {selectedBand.Band_address || 'N/A'}</p>
                        <p><strong>Description:</strong> {selectedBand.Band_description || 'N/A'}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            )}

            {selectedProposal && (
                <Modal show={showProposalModal} onHide={handleCloseProposalModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Proposal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Select Gig</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedGig}
                                    onChange={(e) => setSelectedGig(e.target.value)}
                                >
                                    <option value="">Select a gig</option>
                                    {gigs.map(gig => (
                                        <option key={gig.Gig_id} value={gig.Gig_id}>
                                            {gig.Gig_Title}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Proposal Price</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="proposalPrice"
                                    value={proposalPrice}
                                    onChange={handleProposalInputChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Proposal Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="proposalMessage"
                                    value={proposalMessage}
                                    onChange={handleProposalInputChange}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseProposalModal}>Close</Button>
                        <Button variant="primary" onClick={handleSendProposal}>Send Proposal</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default BandListComponent;

