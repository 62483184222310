import React from 'react';

function ComingSoon() {
  return (
    <div>
      <h1>Coming Soon</h1>
      <p>We’re working hard to launch our site. Stay tuned!</p>
    </div>
  );
}

export default ComingSoon;
