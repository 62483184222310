import React, { useState, useEffect } from 'react';
import { Button, Modal, Dropdown, Form, Spinner } from 'react-bootstrap';
import { FaInfoCircle, FaEdit, FaUser, FaReply } from 'react-icons/fa';
import './GigProposalList.css';
import GetUserInfo from '../GetUserInfo';
import moment from 'moment';

//THIS IS FOR THE BAND

const BandAccountGigOrganizerProposalList = ({ userID }) => {
    const [proposals, setProposals] = useState([]);
    const [selectedProposal, setSelectedProposal] = useState(null);
    const [selectedRegistrationStatus, setSelectedRegistrationStatus] = useState('');
    const [activeModal, setActiveModal] = useState(null); // Use a single state to manage modals
    const [bandProfile, setBandProfile] = useState(null);
    const [proposalPrice, setProposalPrice] = useState('');
    const [proposalMessage, setProposalMessage] = useState('');
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const [isLoadingProfile, setIsLoadingProfile] = useState(false); 
    const [venueId, setVenueId] = useState(null);

    const [venueLogo, setVenueLogo] = useState(null);
  const [venueName, setVenueName] = useState('');
  const [venueDescription, setVenueDescription] = useState('');
  const [eventDetails, setEventDetails] = useState(null);

  useEffect(() => {
    console.log("USER ID: "+userID);
    fetch(`http://34.232.96.254/BandAccountGetOrganizerProposalsForBand.php?Band_id=${userID}`)
    .then(response => response.json())
    .then(data => {
        // Check if the response contains an error message
        if (data.status === "error" && data.message === "No data found") {
            setProposals([]); // Set proposals to empty array if no data is found
        } else {
            setProposals(data.data); // Otherwise, set proposals as the data
        }
        console.log("Data: " + JSON.stringify(data.data));
    })
    .catch(error => console.error('Error fetching proposals:', error));

}, [userID]);

useEffect(() => {
    if (selectedProposal) {
        fetch(`http://34.232.96.254/GetGigInfo.php?Gig_id=${selectedProposal.Gig_id}`)
            .then(response => response.json())
            .then(data => {
                setVenueId(data.VenueID);
                setEventDetails(data);
            })
            .catch(error => console.error('Error fetching gig info:', error));
    }
}, [selectedProposal]);

const handleStatusChange = (status) => {
    if (selectedProposal) {
        const updatedProposal = { ...selectedProposal, Proposal_Status: status };
        setSelectedProposal(updatedProposal);
        console.log("Status updated to:", updatedProposal.Proposal_Status); // Check if status is changing
    }
};


const handleSaveChanges = () => {
    const selectedProposalID = selectedProposal.Organizer_Proposal_id;

    console.log("Saving proposal with ID:", selectedProposalID, "Status:", selectedProposal.Proposal_Status);

    fetch(`http://34.232.96.254/SetOrganizerProposalStatus.php?Organizer_Proposal_id=${selectedProposalID}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Organizer_Proposal_id: selectedProposalID,
            Proposal_Status: selectedProposal.Proposal_Status,
        }),
    })
    .then(response => response.json())
    .then(() => {
        alert("Proposal status updated successfully");

        console.log("booked Gig address: "+JSON.stringify(eventDetails));

        const payload = {

            Organizer_id: selectedProposal.Organizer_id,
            Band_id: selectedProposal.Band_id,
            Gig_name: selectedProposal.Gig_Title,
            Booked_price: selectedProposal.Proposed_Price, // Adjust this if you store the price differently
            Gig_address: eventDetails.Gig_Location_Address, // Replace with the correct address from event details
            Gig_startdate: eventDetails.Gig_start_date, // Adjust as necessary
            Gig_enddate: eventDetails.Gig_end_date,
            Gig_starttime: eventDetails.Gigs_start_time,
            Gig_endtime: eventDetails.Gigs_end_time,
            Status: "Booked"
        }

        console.log("Payload Booked :"+ JSON.stringify(payload));

        // If status is "Accepted", call BookTheGig.php
        if (selectedProposal.Proposal_Status === "Accepted" && eventDetails && eventDetails.Gig_Location_Address) {
            fetch(`http://34.232.96.254/BookTheGigForBand.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Organizer_id: selectedProposal.Organizer_id,
                    Band_id: selectedProposal.Band_id,
                    Gig_name: selectedProposal.Gig_Title,
                    Booked_price: selectedProposal.Proposed_Price, // Adjust this if you store the price differently
                    Gig_address: eventDetails.Gig_Location_Address, // Replace with the correct address from event details
                    Gig_startdate: eventDetails.Gig_start_date, // Adjust as necessary
                    Gig_enddate: eventDetails.Gig_end_date,
                    Gig_starttime: eventDetails.Gigs_start_time,
                    Gig_endtime: eventDetails.Gigs_end_time,
                    Status: "Booked"

                })
            })
            .then(response => response.json()) // Assuming your PHP returns JSON
            .then(data => {
                if (data.success) {
                    alert("Gig booked successfully");
                } else {
                    console.error("Booking result:", data);
                    alert("Failed to book the gig: " + data.message);
                }
            })
            .catch(error => console.error('Error booking the gig:', error));
            
        }

        // Refresh the proposals list
        fetch(`http://34.232.96.254/BandAccountGetOrganizerProposalsForBand.php?Band_id=${userID}`)
            .then(response => response.json())
            .then(data => setProposals(data.data))
            .catch(error => console.error('Error fetching proposals:', error));
        
        setSelectedProposal(null);
    })
    .catch(error => console.error('Error updating proposal status:', error));
};



    const handleGetGigInfo= async (proposal)=>{

        fetch(`http://34.232.96.254/GetGigInfo.php?Gig_id=${proposal.Gig_id}`)
                .then(response => response.json())
                .then(data => {
                    setVenueId(data.VenueID);
                    setEventDetails(data);
                    console.log("GIG : "+JSON.stringify(eventDetails));
                })
                .catch(error => console.error('Error fetching gig info:', error));
    }

    const handleViewDetails = async (event) => {
        fetch(`http://34.232.96.254/GetGigInfo.php?Gig_id=${event.Gig_id}`)
        .then(response => response.json())
        .then(data => {
            setVenueId(data.VenueID);
            setEventDetails(data);
        })
        .catch(error => console.error('Error fetching gig info:', error));


        try {
          console.log("VenueID: " + event.Venue_id); // Check VenueID before fetch
          const response = await fetch(`http://34.232.96.254/GetVenueInformationByID.php?Venue_ID=${event.Venue_id}`);
          
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          
          const venueData = await response.json();
          console.log("Venue Data:", venueData); // Log fetched venue data for debugging
          
          // Check if Venue_Logo is present and valid base64 encoded string
          if (venueData.Venue_Logo&&venueData.Venue_name&&venueData.Venue_Description) {
            setVenueLogo(`${venueData.Venue_Logo}`); // Ensure correct base64 data URL format
            setVenueName(`${venueData.Venue_name}`);
            setVenueDescription(`${venueData.Venue_Description}`);
          } else {
            console.warn('Venue_Logo not found in venueData:', venueData);
          }

        
         // incrementGigViews(event.id)
         // setEventDetails(event);
         // setShowModal(true);
        } catch (error) {
          console.error('Error fetching venue details:', error);
          alert(`Error: Failed to fetch venue details. ${error.message}`);
        }
      };
    

    const handleDeleteProposal = () => {
        const selectedProposalID = selectedProposal.Organizer_Proposal_id;
        fetch(`http://34.232.96.254/DeleteOrganizerProposal.php?Organizer_Proposal_id=${selectedProposalID}`)
            .then(response => response.json())
            .then(() => {
                alert("Proposal deleted successfully");
                setProposals(proposals.filter(p => p.Organizer_Proposal_id !== selectedProposalID));
                setSelectedProposal(null);
            })
            .catch(error => console.error('Error deleting proposal:', error));
    };

    const fetchBandProfile = (bandId) => {
        setIsLoadingProfile(true); // Start loading
        fetch(`http://34.232.96.254/GetBandInformationByID.php?Band_ID=${bandId}`)
            .then(response => response.json())
            .then(data => {
                console.log("Response: " + JSON.stringify(data.bandData));
                setBandProfile(data.bandData);
                setIsLoadingProfile(false); // Stop loading
                setActiveModal('profile'); // Open the profile modal
            })
            .catch(error => {
                console.error('Error fetching band profile:', error);
                setIsLoadingProfile(false); // Stop loading on error
            });
    
    };

    const formatDate = (date) => {
        const pad = (num) => (num < 10 ? '0' + num : num);
    
        const day = pad(date.getDate());
        const month = pad(date.getMonth() + 1); // Months are zero-based
        const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());
    
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    };

    const handleSendProposal = async (proposal) => {
        try {
            console.log("Gig_id from Send Registration:"+proposal.id);
              // Get attendee name and email
             // const attendee_name = await GetUserInfo.GetUsernameFromIDForBand(user);
              //const attendee_email = await GetUserInfo.GetBandEmailFromID(user);
      
              // Get request by name and email
              //const request_by_name = event.name;
              //const request_by_email = await GetUserInfo.GetOrganizerEmailForEvent(event.id);
      
              const Organizer_ID =  await GetUserInfo.GetOrganizerIDForEvent(proposal.Organizer_id);
              const VenueDetails = await GetUserInfo.GetVenueDetailsForEvent(proposal.Gig_id);
              const BandDetails =await GetUserInfo.GetBandDetailsByUserID(userID); // band user id
      
             // console.log("Registration DATA:"+registrationData);
      
              
              // Construct registration data
              const registrationData = {
                Gig_id: proposal.Gig_id, // Include event_id here
                Organizer_id:proposal.Organizer_id,
                Band_id:userID,
                Venue:VenueDetails.Venue,
                Venue_id:VenueDetails.VenueID,
                Gig_Title:proposal.Gig_Title,
                Musician_first_name:BandDetails.Musician_first_name,
                Musician_last_name:BandDetails.Musician_last_name,
                Proposed_Price:proposalPrice,
                Proposed_Message:proposalMessage,
                Proposal_Status:"Submitted",
                Band_Email:BandDetails.Band_email,
                Gig_Name:proposal.Gig_Title,
                Date_Registered:formatDate(new Date()),
                Band_name:BandDetails.Band_name
                
          
      
              };
      
              console.log('Registration Data:', registrationData);
      
              const response = await fetch('http://34.232.96.254/SendProposal.php', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      
                  },
                  body: JSON.stringify(registrationData),
              });
      
              if (!response.ok) {
                  throw new Error('Failed to send registration');
              }
      
              const result = await response.json();
      
              if (result.success) {
                  console.log('Registration successful. Registration ID:', result.message);
      
                  window.alert("Registration Sent");
                  
              } else {
                  console.error('Failed to register. Server response:', result.message);
              }
      
              /*
      
              if(result.event_id)
              {
                console.log('Registration event id received from php:',result.event_id);
              }
      
              if(result.meta_data)
              {
                console.log('Registration data received from php:',result.meta_data);
              }
      
              if(result._attendee_user_id)
              {
                console.log('Registration user id received from php:',result._attendee_user_id);
              }
      
             */
      
          
          } catch (error) {
              console.error('Error sending registration:', error.message);
          }
    };
    
    function createImageUrl(base64String) {
        // Check if the base64 string already contains the prefix
        if (base64String.startsWith('data:image/')) {
            return base64String; // No need to modify
        }
        
        // Define known base64 prefix for JPEG images
        const jpegPrefix = 'data:image/jpeg;base64,';
      
        // Define known base64 prefixes for different image types
        const imageTypes = {
            'iVBORw0KGgo': 'png',  // PNG base64 prefix
            '/9j/4': 'jpeg',      // JPEG base64 prefix
            'R0lGODlh': 'gif'     // GIF base64 prefix (if needed)
        };
      
        // Detect image type
        let imageType = '';
        for (const [prefix, type] of Object.entries(imageTypes)) {
            if (base64String.startsWith(prefix)) {
                imageType = type;
                break;
            }
        }
      
        // If no known image type is found, assume JPEG
        if (!imageType) {
            imageType = 'jpeg';
        }
      
        // Return the data URL with the detected or default image type
        return `data:image/${imageType};base64,${base64String}`;
      }
    

    const getStatusLabel = (status) => {
        switch (status) {
            case 'Accepted':
                return 'Accepted';
            case 'Proposal':
                return 'Proposal';
                case 'Proposed':
                    return 'Proposed';
            case 'Canceled':
                return 'Canceled';
            case 'Archived':
                return 'Archived';
                case 'Submitted':
                return 'Submitted';
        }
    };

    const getStatusColor = (status) => {
        console.log("Status: "+status);
        switch (status) {
            case 'Accepted':
                return 'green';
            case 'Proposal':
                return 'blue';
                case 'Proposed':
                    return 'blue';
            case 'Canceled':
                return 'red';
            case 'Archived':
                return 'gray';
                case 'Submitted':
                return 'orange';
        }
    };

    function convertToStandardTime(time24) {
        // Split the time into hours, minutes, and seconds
        let [hours, minutes, seconds] = time24.split(':');
    
        // Convert hours to a number
        hours = parseInt(hours, 10);
    
        // Determine AM or PM
        const ampm = hours >= 12 ? 'PM' : 'AM';
    
        // Convert hours from 24-hour to 12-hour format
        hours = hours % 12 || 12; // If hour is 0 or 12, display as 12
    
        // Format minutes and seconds with leading zeros if needed
        minutes = minutes.padStart(2, '0');
        seconds = seconds.padStart(2, '0');
    
        // Return the formatted time string
        return `${hours}:${minutes}:${seconds} ${ampm}`;
    }
    

    return (
        <div className="gig-proposal-list">
            {proposals.length === 0 ? (
                <p>No proposals available at the moment.</p>
            ) : (
                proposals.map(proposal => (
                    <div key={proposal.Organizer_Proposal_id} className="proposal-card">
                        <div className="proposal-info">
                            <div className="proposal-title">{proposal.Gig_Title}</div>
                            <div className="proposal-venue">{proposal.Venue}</div>
                            <div className="proposal-date-registered">Date Registered: {proposal.Date_Registered}</div>
                        </div>
                        <div className="proposal-footer">
                            <div className="proposal-status" style={{ backgroundColor: getStatusColor(proposal.Proposal_Status) }}>
                                {getStatusLabel(proposal.Proposal_Status)}
                            </div>
                            <Button
                                variant="info"
                                className="details-button"
                                onClick={() => {
                                    setSelectedProposal(proposal);
                                    setActiveModal('details'); // Open the details modal
                                }}
                            >
                                <FaInfoCircle />
                            </Button>
                            <Button
                                variant="warning"
                                className="edit-button"
                                onClick={() => {
                                    setSelectedProposal(proposal);
                                    setSelectedRegistrationStatus(proposal.Proposal_Status);
                                    setActiveModal('edit'); // Open the edit modal
                                }}
                                disabled={proposal.Proposal_Status === 'Accepted'}
                            >
                                <FaEdit />
                            </Button>
                            <Button
                                variant="primary"
                                className="profile-button"
                                onClick={() => {
                                    setSelectedProposal(proposal);
                                    handleGetGigInfo(proposal);
                                    handleViewDetails(proposal);
                                    setIsProfileModalOpen(true);
                                }}
                            >
                                <FaUser />
                            </Button>
                            <Button
                                variant="success"
                                className="reply-button"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevents event bubbling
                                    setSelectedProposal(proposal);
                                    setActiveModal('reply'); // Open the reply modal
                                }}
                            >
                                <FaReply /> Reply
                            </Button>
                        </div>
                    </div>
                ))
            )}
    
            {/* Details Modal */}
            <Modal show={activeModal === 'details' && selectedProposal !== null} onHide={() => setActiveModal(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Proposal Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Price:</strong> {selectedProposal?.Proposed_Price}</p>
                    <p style={{ paddingTop: '10px' }}><strong>Message:</strong> {selectedProposal?.Proposed_Message}</p>
                    <p><strong>Organizer Email:</strong> {selectedProposal?.Organizer_email}</p>
                    <p><strong>Gig:</strong> {selectedProposal?.Gig_Title}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setActiveModal(null)}>Close</Button>
                </Modal.Footer>
            </Modal>
    
            {/* Edit Modal */}
            <Modal show={activeModal === 'edit' && selectedProposal !== null} onHide={() => setActiveModal(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Proposal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Registration Status</strong></p>
                    <Dropdown onSelect={handleStatusChange} disabled={selectedProposal?.Proposal_Status === 'Accepted'}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {selectedProposal ? selectedProposal.Proposal_Status : "Select Status"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="Accepted">Accepted</Dropdown.Item>
                            <Dropdown.Item eventKey="Proposal">Proposal</Dropdown.Item>
                            <Dropdown.Item eventKey="Canceled">Canceled</Dropdown.Item>
                            <Dropdown.Item eventKey="Archived">Archived</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setActiveModal(null)}>Close</Button>
                    <Button variant="primary" onClick={handleSaveChanges}>Save Changes</Button>
                    <Button variant="danger" onClick={handleDeleteProposal}>Delete</Button>
                </Modal.Footer>
            </Modal>
    
            {/* Profile Modal */}
            <Modal show={isProfileModalOpen} onHide={() => setIsProfileModalOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Organizer Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {venueLogo && (
                        <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                            <img
                                src={createImageUrl(venueLogo)}
                                alt="Venue Logo"
                                style={{ maxHeight: '200px', maxWidth: '100%', objectFit: 'contain' }}
                            />
                            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                <h5>Venue Name: {venueName}</h5>
                                <h5>Venue Description: {venueDescription}</h5>
                            </div>
                        </div>
                    )}
                    <h5>
                        Start Time: {eventDetails?.Gigs_start_time ? convertToStandardTime(eventDetails.Gigs_start_time) : 'N/A'}
                    </h5>
                    <h5>
                        End Time: {eventDetails?.Gigs_end_time ? convertToStandardTime(eventDetails.Gigs_end_time) : 'N/A'}
                    </h5>
                    <h5>Address: {eventDetails?.Gig_Location_Address}</h5>
                    <p>{eventDetails?.Gig_Description}</p>
                    {eventDetails?.Gig_Location_Address && (
                        <div style={{ marginTop: '20px', textAlign: 'center' }}>
                            <iframe
                                title="Google Map"
                                width="100%"
                                height="300"
                                frameBorder="0"
                                style={{ border: 0 }}
                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAF2Q8fA6MwI4ggg-uxaSnOk4tCeCZH7Ds&q=${encodeURIComponent(eventDetails.Gig_Location_Address)}`}
                                allowFullScreen
                            ></iframe>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsProfileModalOpen(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
    
            {/* Reply Modal */}
            <Modal show={activeModal === 'reply' && selectedProposal !== null} onHide={() => setActiveModal(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Reply to Proposal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formPrice">
                            <Form.Label>Proposal Price</Form.Label>
                            <Form.Control
                                type="text"
                                value={proposalPrice}
                                onChange={(e) => setProposalPrice(e.target.value)}
                                placeholder="Enter price"
                            />
                        </Form.Group>
                        <Form.Group controlId="formMessage">
                            <Form.Label>Proposal Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={proposalMessage}
                                onChange={(e) => setProposalMessage(e.target.value)}
                                placeholder="Enter message"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setActiveModal(null)}>Close</Button>
                    <Button variant="primary" onClick={() => handleSendProposal(selectedProposal)}>Send Proposal</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default BandAccountGigOrganizerProposalList;
