import React, { useState, useEffect } from 'react';
import './App.css';
import EventListComponent from './Components/EventListComponent';
import TopBar from './Components/TopBar';
import MainTopBar from './Components/MainTopBar';
import CalendarComponent from './Components/CalendarComponent';
import HomeScreen from './Screens/Homescreen';
import BottomBarComponent from './Components/BottomBarComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Components/AccountLogin';
import CalendarScreen from './Screens/CalendarScreen';
import EventListScreen from './Screens/EventListScreen';
import { Modal } from 'react-bootstrap';
import BandRegistrationScreen from './Screens/BandRegistrationScreen';
import OrganizerRegistrationScreen from './Screens/OrganizerRegistrationScreen';
import AccountScreen from './Screens/AccountScreen';
import MobileBandRegistration from './Components/MobileBandRegistration';
import MobileOrganizerRegistration from './Components/MobileOrganizerRegistration';
import MobileBandAccountDetails from './Components/MobileBandAccountDetails';
import MobileOrganizerAccountDetails from './Components/MobileOrganizerAccountDetails';
import ComingSoon from './Components/ComingSoon';

function App() {
  const [view, setView] = useState('home');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showBandRegistrationModal, setShowBandRegistrationModal] = useState(false);
  const [showOrganizerRegistrationModal, setShowOrganizerRegistrationModal] = useState(false);
  const [lastActiveView, setLastActiveView] = useState('home');

  // Ensure comingSoonMode is set to 'false' if not already set
  const storedMode = localStorage.getItem('comingSoonMode');


  useEffect(() => {
    // Check if 'comingSoonMode' is falsy and set it to 'true'
    if (!storedMode) {
      localStorage.setItem('comingSoonMode', 'true');
    }
  }, []);
  

  useEffect(() => {
    const handleInitialHashNavigation = () => {
      const hash = window.location.hash.substr(1).toLowerCase(); // Convert hash to lowercase
  
      if (hash === 'bandregistration') {
        setView('BandRegistration');
      } else if (hash === 'organizerregistration') {
        setView('OrganizerRegistration');
      } else if (hash === 'login') {
        setView('login');
      } else if (hash === 'mobilebandregistration') {
        setView('MobileBandRegistration');
      } else if (hash === 'mobileorganizerregistration') {
        setView('MobileOrganizerRegistration');
      } else if (hash === 'mobilebandaccountdetails') {
        setView('MobileBandAccountDetails');
      } else if (hash === 'mobileorganizeraccountdetails') {
        setView('MobileOrganizerAccountDetails');
      }
    };
  
    // Handle initial hash navigation
    handleInitialHashNavigation();
  
    // Listen to hash changes
    window.addEventListener('hashchange', handleInitialHashNavigation);
  
    return () => {
      window.removeEventListener('hashchange', handleInitialHashNavigation);
    };
  }, [view]);

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
    if (storedMode === 'false') {
      setView(lastActiveView);
    }
  };

  const handleShowLoginModal = () => {
    setLastActiveView(view);
    setView('login');
    setShowLoginModal(true);
  };

  const handleCloseBandRegistrationModal = () => {
    setShowBandRegistrationModal(false);
  };

  const handleShowBandRegistrationModal = () => {
    setShowBandRegistrationModal(true);
  };

  const handleCloseOrganizerRegistrationModal = () => {
    setShowOrganizerRegistrationModal(false);
  };

  const handleShowOrganizerRegistrationModal = () => {
    setShowOrganizerRegistrationModal(true);
  };

  const handleChangeView = (newView) => {
    if (newView === 'login') {
      handleShowLoginModal();
    } else {
      setView(newView);
      window.location.hash = newView.toLowerCase();
    }
  };

  const onLogin = () => {
    setView('Account');
    window.location.hash = 'account';
  };

  return (
    <div className="App" style={{ backgroundColor: '#e2e6e9' }}>
        {storedMode !== 'true' && (
    <>
      {view !== 'MobileBandRegistration' && view !== 'MobileOrganizerRegistration' && view !== 'MobileOrganizerAccountDetails' && view !== 'MobileBandAccountDetails' && (
        <MainTopBar
          setView={handleChangeView}
          handleShowBandRegistrationModal={handleShowBandRegistrationModal}
          handleShowOrganizerRegistrationModal={handleShowOrganizerRegistrationModal}
        />
      )}
      </>
    )}

      <Modal show={showLoginModal} onHide={handleCloseLoginModal} centered dialogClassName="full-screen-modal">
        <Modal.Header closeButton />
        <Modal.Body>
          <Login onLogin={onLogin} />
        </Modal.Body>
      </Modal>

      <Modal show={showBandRegistrationModal} onHide={handleCloseBandRegistrationModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Band Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BandRegistrationScreen />
        </Modal.Body>
      </Modal>

      <Modal show={showOrganizerRegistrationModal} onHide={handleCloseOrganizerRegistrationModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Organizer Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrganizerRegistrationScreen />
        </Modal.Body>
      </Modal>

      <div className="main-content">
  {storedMode !== 'true' && (
    <>
      {view !== 'home' && view !== 'login' && view !== 'BandRegistration' && view !== 'OrganizerRegistration' && view !== 'Account' && view !== 'MobileBandRegistration' && view !== 'MobileOrganizerRegistration' && view !== 'MobileOrganizerAccountDetails' && view !== 'MobileBandAccountDetails' && <TopBar view={view} setView={setView} />}
      {view === 'list' && <EventListScreen key="eventList" />}
      {view === 'calendar' && <CalendarScreen key="calendar" />}
      {view === 'home' && <HomeScreen key="homeScreen" />}
      {view === 'Account' && <AccountScreen />}
      {view !== 'list' && view !== 'calendar' && view !== 'login' && view !== 'BandRegistration' && view !== 'OrganizerRegistration' && view !== 'Account' && view !== 'MobileBandRegistration' && view !== 'MobileOrganizerRegistration' && view !== 'MobileOrganizerAccountDetails' && view !== 'MobileBandAccountDetails' && <BottomBarComponent view={view} setView={setView} />}
      {view === 'MobileBandAccountDetails' && <MobileBandAccountDetails />}
      {view === 'MobileOrganizerAccountDetails' && <MobileOrganizerAccountDetails />}
      {view === 'MobileBandRegistration' && <MobileBandRegistration />}
      {view === 'MobileOrganizerRegistration' && <MobileOrganizerRegistration />}
      {view === 'BandRegistration' && <BandRegistrationScreen />}
      {view === 'OrganizerRegistration' && <OrganizerRegistrationScreen />}
      {view === 'login' && lastActiveView === 'home' && <HomeScreen />}
      {view === 'login' && lastActiveView === 'list' && <EventListScreen />}
      {view === 'login' && lastActiveView === 'calendar' && <CalendarScreen />}
    </>
  )}
  
</div>


      {storedMode === 'true' && <ComingSoon />} {/* Render ComingSoon component if storedMode is 'false' */}
    </div>
  );
}

export default App;
