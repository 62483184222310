import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import countriesArray from './CountriesLibrary';
import generateGeolocation from './generateGeolocation';
import './DuplicateGigComponent.css';

const DuplicateGigComponent = ({ gigData, UserIDnumber, onHide,onUpdate }) => {
  const [venues, setVenues] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(gigData.VenueID || '');
  const [gigTitle, setGigTitle] = useState(gigData.Gig_Title || '');
  const [gigAddress, setGigAddress] = useState(gigData.Gig_Location_Address || '');
  const [zipcode, setZipcode] = useState(gigData.Gig_Zipcode || '');
  const [selectedCountry, setSelectedCountry] = useState(gigData.Gig_Countrycode || '');
  const [description, setDescription] = useState(gigData.Gig_Description || '');
  const [genre, setGenre] = useState(gigData.Gig_Genre || '');
  const [startDate, setStartDate] = useState(new Date(gigData.Gig_start_date) || new Date());
  const [endDate, setEndDate] = useState(new Date(gigData.Gig_end_date) || new Date());
  const [gigImage, setGigImage] = useState(gigData.Gig_Image || '');
  const [isSaving, setIsSaving] = useState(false);
  const [loadingVenues, setLoadingVenues] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        const response = await fetch(`http://34.232.96.254/GetVenuesByOrganizerID.php?OrganizerID=${UserIDnumber}`);
        const data = await response.json();
        setVenues(data);
      } catch (error) {
        setFetchError('Error fetching venues');
      } finally {
        setLoadingVenues(false);
      }
    };

    fetchVenues();
  }, [UserIDnumber]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      // Update the state with the base64 data URL of the image
      setGigImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    
    return { formattedDateTime, formattedTime };
  };

  const handleSaveGig = async () => {
    if(alertMessage==null)
      {
      setAlertMessage("Processing...");
    if (!gigTitle || !gigAddress || !zipcode || !description) {
      setShowErrorModal(true);
      setAlertMessage(null);
      return;
    }

    setIsSaving(true);
    try {
      const geolocationData = await generateGeolocation(gigAddress, selectedCountry, zipcode);
      const gigDataForSave = {
        Gig_data: startDate,
        Gig_created_date: formatDateTime(new Date()).formattedDateTime,
        Gig_start_date: formatDateTime(startDate).formattedDateTime,
        Gig_end_date:  formatDateTime(endDate).formattedDateTime,
        Organizer_id: UserIDnumber,
        Band_id: "0", 
        Venue: venues.find(venue => venue.Venue_ID === parseInt(selectedVenue))?.Venue_name || '',
        VenueID: selectedVenue,
        Gig_Title: gigTitle,
        Gigs_start_time: formatDateTime(startDate).formattedTime,
        Gigs_end_time: formatDateTime(endDate).formattedTime,
        Gig_Status: 'Scheduled',
        Gig_GeolocationData: JSON.stringify({
          latitude: geolocationData.geolocation_lat,
          longitude: geolocationData.geolocation_long
        }),
        Gig_Confirmed_Registrations: "0",
        Gig_Image: gigImage, // Pass the base64 data URL
        Gig_Description: description,
        Gig_Location_Address: gigAddress,
        Gig_Zipcode: zipcode,
        Gig_Countrycode: selectedCountry,
        Geolocation_lat: geolocationData.geolocation_lat,
        Geolocation_long: geolocationData.geolocation_long,
        Geolocation_formatted_address: geolocationData.geolocation_formatted_address,
        Geolocation_city: geolocationData.geolocation_city,
        Geolocation_state_short: geolocationData.geolocation_state_short,
        Geolocation_state_long: geolocationData.geolocation_state_long,
        Geolocation_postcode: geolocationData.geolocation_postcode,
        Geolocation_country_short: geolocationData.geolocation_country_short,
        Geolocation_country_long: geolocationData.geolocation_country_long,
        Gig_Views: '0'
      };

      const response = await fetch('http://34.232.96.254/CreateGig.php', {
        method: 'POST',
       
        body: JSON.stringify(gigDataForSave)
      });
      const data = await response.json();
      console.log('Gig saved successfully:', data);
      onUpdate();
      setAlertMessage(null);
      setShowSuccessModal(true);
    } catch (error) {
      setAlertMessage(null);
      console.error('Error saving gig:', error);
    } finally {
      setAlertMessage(null);
      setIsSaving(false);
    }
  }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    onHide(); // Close the duplicate modal
  };

  const handleCloseErrorModal = () => setShowErrorModal(false);

  if (loadingVenues) {
    return <Container><p>Loading venues...</p></Container>;
  }

  if (fetchError) {
    return <Container><Alert variant="danger">{fetchError}</Alert></Container>;
  }
  function createImageUrl(base64String) {
    // Check if the base64 string already contains the prefix
    if (base64String.startsWith('data:image/')) {
        return base64String; // No need to modify
    }
    
    // Define known base64 prefix for JPEG images
    const jpegPrefix = 'data:image/jpeg;base64,';

    // Define known base64 prefixes for different image types
    const imageTypes = {
        'iVBORw0KGgo': 'png',  // PNG base64 prefix
        '/9j/4': 'jpeg',      // JPEG base64 prefix
        'R0lGODlh': 'gif'     // GIF base64 prefix (if needed)
    };

    // Detect image type
    let imageType = '';
    for (const [prefix, type] of Object.entries(imageTypes)) {
        if (base64String.startsWith(prefix)) {
            imageType = type;
            break;
        }
    }

    // If no known image type is found, assume JPEG
    if (!imageType) {
        imageType = 'jpeg';
    }

    // Return the data URL with the detected or default image type
    return `data:image/${imageType};base64,${base64String}`;
}

  return (
    <Container style={{ paddingTop: '20px' }}>
      <Form>
        <Form.Group as={Row} controlId="gigTitle">
          <Form.Label column sm={2}>* Gig Title:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={gigTitle} onChange={(e) => setGigTitle(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="venues">
          <Form.Label column sm={2}>Venues:</Form.Label>
          <Col sm={10}>
            <Form.Control as="select" value={selectedVenue} onChange={(e) => setSelectedVenue(e.target.value)}>
              <option value="">Select a venue</option>
              {venues.map((venue) => (
                <option key={venue.Venue_ID} value={venue.Venue_ID}>{venue.Venue_name}</option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="gigAddress">
          <Form.Label column sm={2}>Location * Example: "Street Address, City, State 'FL'":</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={gigAddress} onChange={(e) => setGigAddress(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="zipcode">
          <Form.Label column sm={2}>Zip Code *:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="country">
          <Form.Label column sm={2}>Country:</Form.Label>
          <Col sm={10}>
            <Form.Control as="select" value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
              {countriesArray.map((country) => (
                <option key={country.code} value={country.code}>{country.name}</option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="description">
          <Form.Label column sm={2}>Description *:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="genre">
          <Form.Label column sm={2}>Genre:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={genre} onChange={(e) => setGenre(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Col sm={10}>
            <Button variant="secondary" onClick={() => document.getElementById('fileInput').click()} className="image-button">Upload Gig Image</Button>
            <input id="fileInput" type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageUpload} />
            {gigImage && <img src={createImageUrl(gigImage)} alt="Gig" style={{ width: '100px', height: '100px', marginTop: '10px',padding:'10px' }} />}
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="startDate">
          <Form.Label column sm={2}>Start Date & Time:</Form.Label>
          <Col sm={10}>
          <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showTimeSelect
              dateFormat="Pp"
              className="form-control"
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="endDate">
          <Form.Label column sm={2}>End Date & Time:</Form.Label>
          <Col sm={10}>
          <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              showTimeSelect
              dateFormat="Pp"
              className="form-control"
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Col sm={10}>
          {alertMessage && (
    <div
        style={{
            marginTop: 20,
            padding: 15,
            backgroundColor: '#d4edda',
            borderRadius: 5,
            border: '1px solid #c3e6cb',
        }}
    >
        <span
            style={{
                color: '#155724',
                fontSize: 16,
                fontWeight: 'bold',
            }}
        >
            {alertMessage}
        </span>
    </div>
)}
            <Button variant="primary" onClick={handleSaveGig} disabled={isSaving}>
              {isSaving ? 'Saving...' : 'Save Gig'}
            </Button>
          </Col>
        </Form.Group>
      </Form>

      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your gig has been successfully saved!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSuccessModal}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please fill in all required fields.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseErrorModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DuplicateGigComponent;
