import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Modal, Image } from 'react-bootstrap';
import VenueCount from './VenueCount';

const VenueCreatorComponent = ({ UserIDnumber }) => {
  const [venueName, setVenueName] = useState('');
  const [venueDescription, setVenueDescription] = useState('');
  const [venueLocation, setVenueLocation] = useState('');
  const [venueZipcode, setVenueZipcode] = useState('');
  const [logoBase64, setLogoBase64] = useState('');
  const [facebookURL, setFacebookURL] = useState('');
  const [instagramURL, setInstagramURL] = useState('');
  const [youtubeURL, setYoutubeURL] = useState('');
  const [twitterURL, setTwitterURL] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [totalVenues, setTotalVenues] = useState(0);
  const [limitVenues] = useState(5);
  const [alertMessage, setAlertMessage] = useState(null);

  const [componentKey, setComponentKey] = useState(0); // Key to force re-render

  useEffect(() => {
    const fetchTotalVenues = async () => {
      try {
        const response = await fetch(`http://34.232.96.254/GetTotalNumberOfVenues.php?organizerID=${UserIDnumber}`);
        const data = await response.json();

        if (response.ok) {
          setTotalVenues(data.totalVenues || 0);
        } else {
          console.error('Failed to fetch total venues:', data.error || 'Unknown error');
        }
      } catch (error) {
        console.error('Error fetching total venues:', error.message || 'Unknown error');
      }
    };

    fetchTotalVenues();
  }, [UserIDnumber, componentKey]);

  function createImageUrl(base64String) {
    // Check if the base64 string already contains the prefix
    if (base64String.startsWith('data:image/')) {
        return base64String; // No need to modify
    }
    
    // Define known base64 prefix for JPEG images
    const jpegPrefix = 'data:image/jpeg;base64,';

    // Define known base64 prefixes for different image types
    const imageTypes = {
        'iVBORw0KGgo': 'png',  // PNG base64 prefix
        '/9j/4': 'jpeg',      // JPEG base64 prefix
        'R0lGODlh': 'gif'     // GIF base64 prefix (if needed)
    };

    // Detect image type
    let imageType = '';
    for (const [prefix, type] of Object.entries(imageTypes)) {
        if (base64String.startsWith(prefix)) {
            imageType = type;
            break;
        }
    }

    // If no known image type is found, assume JPEG
    if (!imageType) {
        imageType = 'jpeg';
    }

    // Return the data URL with the detected or default image type
    return `data:image/${imageType};base64,${base64String}`;
}

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoBase64(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const resetForm = () => {
    setVenueName('');
    setVenueDescription('');
    setVenueLocation('');
    setVenueZipcode('');
    setLogoBase64('');
    setFacebookURL('');
    setInstagramURL('');
    setYoutubeURL('');
    setTwitterURL('');
    document.getElementById('fileInput').value = ''; // Reset the file input
  };

  const handleSaveVenue = async () => {
    if(alertMessage==null)
      {
      setAlertMessage("Processing...");
    if (totalVenues >= limitVenues) {
      setShowLimitModal(true);
      setAlertMessage(null); 
      return;
    }

    if (!venueName || !venueLocation || !venueZipcode || !venueDescription) {
      setShowErrorModal(true);
      setAlertMessage(null); 
      return;
    }

    const requestBody = {
      Organizer_ID: UserIDnumber,
      Venue_name: venueName,
      Venue_email: '',
      Venue_Website: '',
      Venue_registered_date: new Date().toISOString().split('T')[0],
      Organizer_first_name: '',
      Organizer_last_name: '',
      Venue_city: '',
      Venue_address: venueLocation,
      Venue_state: '',
      Venue_zipcode: venueZipcode,
      Venue_Country: '',
      Venue_Phone: '',
      Venue_Mobile: '',
      Venue_date_last_active: new Date().toISOString().split('T')[0],
      Venue_Description: venueDescription,
      Venue_Logo: logoBase64.split(',')[1], // Strip out the prefix for saving
      Venue_FaceBook: facebookURL,
      Venue_Instagram: instagramURL,
      Venue_Youtube: youtubeURL,
      Venue_X_Twitter: twitterURL,
    };

    try {
      const response = await fetch('http://34.232.96.254/CreateVenue.php', {
        method: 'POST',
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      console.log('Venue saved successfully:', data);
      setShowSuccessModal(true);
      setAlertMessage(null); 
      resetForm();
      setComponentKey((prevKey) => prevKey + 1); // Force re-render to refresh VenueCount
    } catch (error) {
      setAlertMessage(null); 
      console.error('Error saving venue:', error);
    }

    }
  };

  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const handleCloseLimitModal = () => setShowLimitModal(false);

  return (
    <Container style={{ paddingTop: '20px' }}>
      <VenueCount userID={UserIDnumber} key={componentKey} />
      <Form>
        <Form.Group as={Row} controlId="venueName" className="mb-3">
          <Form.Label column sm={2}>
            Venue Name:
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={venueName} onChange={(e) => setVenueName(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="venueDescription" className="mb-3">
          <Form.Label column sm={2}>
            Venue Description:
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={venueDescription} onChange={(e) => setVenueDescription(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="venueLocation" className="mb-3">
          <Form.Label column sm={2}>
            Venue Location: Example: Street Address, City, State "FL":
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={venueLocation} onChange={(e) => setVenueLocation(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="venueZipcode" className="mb-3">
          <Form.Label column sm={2}>
            Venue Zipcode:
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={venueZipcode} onChange={(e) => setVenueZipcode(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="venueLogo" className="mb-3">
          <Form.Label column sm={2}>
            Venue Logo:
          </Form.Label>
          <Col sm={10}>
            <Button variant="secondary" onClick={() => document.getElementById('fileInput').click()}>
              Select Logo
            </Button>
            <input id="fileInput" type="file" accept="image/*" style={{ display: 'none' }} onChange={handleLogoUpload} />
            {logoBase64 && <Image src={createImageUrl(logoBase64)} alt="Venue Logo" thumbnail />}
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="facebookURL" className="mb-3">
          <Form.Label column sm={2}>
            Facebook URL *Optional*:
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={facebookURL} onChange={(e) => setFacebookURL(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="instagramURL" className="mb-3">
          <Form.Label column sm={2}>
            Instagram URL *Optional*:
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={instagramURL} onChange={(e) => setInstagramURL(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="youtubeURL" className="mb-3">
          <Form.Label column sm={2}>
            YouTube URL *Optional*:
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={youtubeURL} onChange={(e) => setYoutubeURL(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="twitterURL" className="mb-3">
          <Form.Label column sm={2}>
            Twitter URL *Optional*:
          </Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={twitterURL} onChange={(e) => setTwitterURL(e.target.value)} />
          </Col>
        </Form.Group>

        {alertMessage && (
    <div
        style={{
            marginTop: 20,
            padding: 15,
            backgroundColor: '#d4edda',
            borderRadius: 5,
            border: '1px solid #c3e6cb',
        }}
    >
        <span
            style={{
                color: '#155724',
                fontSize: 16,
                fontWeight: 'bold',
            }}
        >
            {alertMessage}
        </span>
    </div>
)}


        <Button variant="primary" onClick={handleSaveVenue}>
          Save Venue
        </Button>
      </Form>

      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Venue saved successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please fill in all required fields.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseErrorModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLimitModal} onHide={handleCloseLimitModal}>
        <Modal.Header closeButton>
          <Modal.Title>Limit Reached</Modal.Title>
        </Modal.Header>
        <Modal.Body>You have reached the limit of {limitVenues} venues.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLimitModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default VenueCreatorComponent;
