import React, { useState, useEffect } from 'react';
import { Button, Modal, Dropdown, Form, Spinner } from 'react-bootstrap';
import { FaInfoCircle, FaEdit, FaUser, FaReply } from 'react-icons/fa';
import './GigProposalList.css';
import GetUserInfo from '../GetUserInfo';

//THIS IS FOR THE ORGANIZER

const GigProposalList = ({ userID }) => {
    const [proposals, setProposals] = useState([]);
    const [selectedProposal, setSelectedProposal] = useState(null);
    const [selectedRegistrationStatus, setSelectedRegistrationStatus] = useState('');
    const [activeModal, setActiveModal] = useState(null); // Use a single state to manage modals
    const [bandProfile, setBandProfile] = useState(null);
    const [proposalPrice, setProposalPrice] = useState('');
    const [proposalMessage, setProposalMessage] = useState('');
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const [isLoadingProfile, setIsLoadingProfile] = useState(false); 
    const [venueId, setVenueId] = useState(null);
    const [selectedGig,setGig] = useState(null);

    useEffect(() => {
        fetch(`http://34.232.96.254/GetProposalsByOrganizerID.php?Organizer_id=${userID}`)
        .then(response => response.json())
        .then(data => {
            if (data.success === false) {
                setProposals([]); // Set an empty array if no records are found
            } else {
                setProposals(data.data); // Set the fetched proposals if records are found
            }
        })
        .catch(error => console.error('Error fetching proposals:', error));
    }, [userID]);

    useEffect(() => {
        if (selectedProposal) {
            fetch(`http://34.232.96.254/GetGigInfo.php?Gig_id=${selectedProposal.Gig_id}`)
                .then(response => response.json())
                .then(data => {
                    setVenueId(data.VenueID);
                    setGig(data);
                })
                .catch(error => console.error('Error fetching gig info:', error));
        }
    }, [selectedProposal]);

    const handleStatusChange = (status) => {
        setSelectedRegistrationStatus(status);
    };

    const handleSaveChanges = () => {
        const selectedProposalID = selectedProposal.Band_Proposal_id;
    
        // First, update the proposal status
        fetch(`http://34.232.96.254/SetProposalStatus.php?Band_Proposal_id=${selectedProposalID}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Band_Proposal_id: selectedProposalID,
                Proposal_Status: selectedRegistrationStatus,
            })
        })
        .then(response => response.json())
        .then(() => {
            alert("Proposal status updated successfully");
    
            // Update the proposals state locally
            setProposals(proposals.map(p => 
                p.Band_Proposal_id === selectedProposalID ? { ...p, Proposal_Status: selectedRegistrationStatus } : p
            ));
            setSelectedProposal(null);

            const payLoad = {

                Organizer_id: userID,
                    Band_id: selectedProposal.Band_id,
                    Gig_name: selectedProposal.Gig_Title,
                    Booked_price: selectedProposal.Proposed_Price, // Adjust this if you store the price differently
                    Gig_address: selectedGig.Gig_Location_Address, // Replace with the correct address from event details
                    Gig_startdate: selectedGig.Gig_start_date, // Adjust as necessary
                    Gig_enddate: selectedGig.Gig_end_date,
                    Gig_starttime: selectedGig.Gigs_start_time,
                    Gig_endtime: selectedGig.Gigs_end_time,
                    Status: "Booked"
            }

          

            console.log("PayLoad: "+JSON.stringify(payLoad));

               
            // If the status is "Accepted", proceed to book the gig
            if (selectedRegistrationStatus === "Accepted") {
                fetch(`http://34.232.96.254/BookTheGig.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Organizer_id: userID,
                    Band_id: selectedProposal.Band_id,
                    Gig_name: selectedProposal.Gig_Title,
                    Booked_price: selectedProposal.Proposed_Price, // Adjust this if you store the price differently
                    Gig_address: selectedGig.Gig_Location_Address, // Replace with the correct address from event details
                    Gig_startdate: selectedGig.Gig_start_date, // Adjust as necessary
                    Gig_enddate: selectedGig.Gig_end_date,
                    Gig_starttime: selectedGig.Gigs_start_time,
                    Gig_endtime: selectedGig.Gigs_end_time,
                    Status: "Booked"
                    })
                })
                .then(response => response.json())
                .then(() => {
                    alert("Gig has been booked successfully!");
    
                    // Notify the band via email
                    const subject = "Your Proposal Has Been Accepted";
                    const body = "Congratulations! Your proposal has been accepted.";
                    window.location.href = `mailto:${selectedProposal.Band_Email}?subject=${subject}&body=${body}`;
                })
                .catch(error => console.error('Error booking the gig:', error));
            }
        })
        .catch(error => console.error('Error updating proposal status:', error));
    };
    

    const handleDeleteProposal = () => {
        const selectedProposalID = selectedProposal.Band_Proposal_id;
        fetch(`http://34.232.96.254/DeleteProposalByID.php?Band_Proposal_id=${selectedProposalID}`)
            .then(response => response.json())
            .then(() => {
                alert("Proposal deleted successfully");
                setProposals(proposals.filter(p => p.Band_Proposal_id !== selectedProposalID));
                setSelectedProposal(null);
            })
            .catch(error => console.error('Error deleting proposal:', error));
    };

    const fetchBandProfile = (bandId) => {
        setIsLoadingProfile(true); // Start loading
        fetch(`http://34.232.96.254/GetBandInformationByID.php?Band_ID=${bandId}`)
            .then(response => response.json())
            .then(data => {
                console.log("Response: " + JSON.stringify(data.bandData));
                setBandProfile(data.bandData);
                setIsLoadingProfile(false); // Stop loading
                setActiveModal('profile'); // Open the profile modal
            })
            .catch(error => {
                console.error('Error fetching band profile:', error);
                setIsLoadingProfile(false); // Stop loading on error
            });
    
    };

    const formatDate = (date) => {
        const pad = (num) => (num < 10 ? '0' + num : num);
    
        const day = pad(date.getDate());
        const month = pad(date.getMonth() + 1); // Months are zero-based
        const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());
    
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    };

    const handleSendProposal = () => {
        // Validate that the necessary data is available
        if (!proposalPrice || !proposalMessage) {
            alert("Please fill in all fields before sending the proposal.");
            return;
        }

        const organizer_email = GetUserInfo.GetOrganizerEmailFromID(userID);

        console.log("organizer email: "+organizer_email);
    
        // Gather the data to send
        const proposalData = {            
            Organizer_id: userID, // Organizer ID (if needed)
            Proposed_Price: proposalPrice, // The price entered by the organizer
            Proposed_Message: proposalMessage, // The message entered by the organizer
            Gig_id: selectedProposal.Gig_id,
            Band_id: selectedProposal.Band_id,
            Gig_Title: selectedProposal.Gig_Title,
            Venue_id: venueId || 'null', // Assuming this is meant to be null
            Venue: selectedProposal.Venue,
            Proposal_Status:"Proposal",
            Date_Registered:formatDate(new Date()),
            Organizer_email:organizer_email,

        };
    
        // Construct the URL with the correct parameters
        const url = `http://34.232.96.254/OrganizerPropose.php?Gig_id=${proposalData.Gig_id}&Organizer_id=${proposalData.Organizer_id}&Band_id=${proposalData.Band_id}&Venue=${encodeURIComponent(proposalData.Venue)}&Gig_Title=${encodeURIComponent(proposalData.Gig_Title)}&Proposed_Price=${proposalData.Proposed_Price}&Venue_id=${proposalData.Venue_id}&Proposed_Message=${encodeURIComponent(proposalData.Proposed_Message)}&Proposal_Status=${encodeURIComponent(proposalData.Proposal_Status)}&Date_Registered=${encodeURIComponent(proposalData.Date_Registered)}&Organizer_email=${encodeURIComponent(proposalData.Organizer_email)}`;
    
        // Make the POST request to the OrganizePropose.php endpoint
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {  // Check for 'status' instead of 'success'
                alert('Proposal sent successfully');
                // Close the reply modal after sending the proposal
                setActiveModal(null);
    
                // Optionally, update the proposals state if needed
            } else {
                alert('Failed to send proposal: ' + data.message);
            }
        })
        .catch(error => {
            console.error('Error sending proposal:', error);
            alert('An error occurred while sending the proposal.');
        });
    };
    
    
    

    const getStatusLabel = (status) => {
        switch (status) {
            case 'Accepted':
                return 'Accepted';
            case 'Proposal':
                return 'Proposal';
            case 'Canceled':
                return 'Canceled';
            case 'Archived':
                return 'Archived';
            default:
                return 'Submitted';
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'Accepted':
                return 'green';
            case 'Proposal':
                return 'blue';
            case 'Canceled':
                return 'red';
            case 'Archived':
                return 'gray';
            default:
                return 'orange';
        }
    };

    return (
        <div className="gig-proposal-list">
        {proposals.length === 0 ? (
            <div className="no-proposals-message">
                There are no proposals.
            </div>
        ) : (
            proposals.map(proposal => (
                <div key={proposal.Band_Proposal_id} className="proposal-card">
                    <div className="proposal-info">
                        <div className="proposal-title">{`${proposal.Band_name}`}</div>
                        <div className="proposal-title">{`${proposal.Musician_first_name} ${proposal.Musician_last_name}`}</div>
                        <div className="proposal-email">{proposal.Band_Email}</div>
                        <div className="proposal-event">Event Registered For: {proposal.Gig_Name}</div>
                        <div className="proposal-event">Date Registered: {proposal.Date_Registered}</div>
                    </div>
                    <div className="proposal-footer">
                        <div className="proposal-status" style={{ backgroundColor: getStatusColor(proposal.Proposal_Status) }}>
                            {getStatusLabel(proposal.Proposal_Status)}
                        </div>
                        <Button
                            variant="info"
                            className="details-button"
                            onClick={() => {
                                setSelectedProposal(proposal);
                                setActiveModal('details'); // Open the details modal
                            }}
                        >
                            <FaInfoCircle />
                        </Button>
                        <Button
                            variant="warning"
                            className="edit-button"
                            onClick={() => {
                                setSelectedProposal(proposal);
                                setSelectedRegistrationStatus(proposal.Proposal_Status);
                                setActiveModal('edit'); // Open the edit modal
                            }}
                            disabled={proposal.Proposal_Status === 'Accepted'}
                        >
                            <FaEdit />
                        </Button>
                        <Button
                            variant="primary"
                            className="profile-button"
                            onClick={() => {fetchBandProfile(proposal.Band_id);setIsProfileModalOpen(true)}}
                        >
                            <FaUser />
                        </Button>
                        <Button
                            variant="success"
                            className="reply-button"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents event bubbling
                                setSelectedProposal(proposal);
                                setActiveModal('reply'); // Open the reply modal
                            }}
                        >
                            <FaReply /> Reply
                        </Button>
                    </div>
                </div>
            ))
        )}
            {/* Details Modal */}
            <Modal show={activeModal === 'details' && selectedProposal !== null} onHide={() => setActiveModal(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Proposal Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Price:</strong> {selectedProposal?.Proposed_Price}</p>
                    <p style={{ paddingTop: '10px' }}><strong>Message:</strong> {selectedProposal?.Proposed_Message}</p>
                    <p><strong>Attendee Email:</strong> {selectedProposal?.Band_Email}</p>
                    <p><strong>Gig:</strong> {selectedProposal?.Gig_Title}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setActiveModal(null)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal */}
            <Modal show={activeModal === 'edit' && selectedProposal !== null} onHide={() => setActiveModal(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Proposal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Registration Status</strong></p>
                    <Dropdown onSelect={handleStatusChange} disabled={selectedProposal?.Proposal_Status === 'Accepted'}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {selectedRegistrationStatus || getStatusLabel(selectedProposal?.Proposal_Status)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="Accepted">Accepted</Dropdown.Item>
                            <Dropdown.Item eventKey="Proposal">Proposal</Dropdown.Item>
                            <Dropdown.Item eventKey="Canceled">Canceled</Dropdown.Item>
                            <Dropdown.Item eventKey="Archived">Archived</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setActiveModal(null)}>Close</Button>
                    <Button variant="primary" onClick={handleSaveChanges}>Save Changes</Button>
                    <Button variant="danger" onClick={handleDeleteProposal}>Delete</Button>
                </Modal.Footer>
            </Modal>

            {/* Profile Modal */}
            <Modal show={isProfileModalOpen} onHide={() => setIsProfileModalOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Band Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {isLoadingProfile ? (
                        <div className="text-center">
                            <Spinner animation="border" /> Loading...
                        </div>
                    ) : bandProfile ? (
                        <div>
                            <h4>{bandProfile.Band_name}</h4>
                           
                            <p><strong>Genre:</strong> {bandProfile.Band_genre}</p>
                            { bandProfile.Band_video&& (
        <div>
          <h3>Preview</h3>
          <video width="400" height = "100%" controls>
          <source src={`data:video/mp4;base64,${bandProfile.Band_video}`} type="video/mp4" />            
            Your browser does not support the video tag.
          </video>
        </div>
        
      )}

{bandProfile.Band_audio && (
          <div>
            <h3>Audio Preview</h3>
            <audio controls>
              <source src={bandProfile.Band_audio} type="audio/mpeg" />
              <source src={bandProfile.Band_audio} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
                        </div>
                    ) : (
                        <p>No band profile data available.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsProfileModalOpen(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Reply Modal */}
            <Modal show={activeModal === 'reply' && selectedProposal !== null} onHide={() => setActiveModal(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Reply to Proposal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formPrice">
                            <Form.Label>Proposal Price</Form.Label>
                            <Form.Control
                                type="text"
                                value={proposalPrice}
                                onChange={(e) => setProposalPrice(e.target.value)}
                                placeholder="Enter price"
                            />
                        </Form.Group>
                        <Form.Group controlId="formMessage">
                            <Form.Label>Proposal Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={proposalMessage}
                                onChange={(e) => setProposalMessage(e.target.value)}
                                placeholder="Enter message"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setActiveModal(null)}>Close</Button>
                    <Button variant="primary" onClick={handleSendProposal}>Send Proposal</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default GigProposalList;


