import React, { useState,useEffect } from 'react';
import axios from 'axios';
import countriesArray from './CountriesLibrary';
import PaymentScreen from '../Screens/PaymentScreen';
import { AlignStart } from 'react-bootstrap-icons';
import './BandRegistration.css';

import { Elements } from '@stripe/react-stripe-js';
import stripeKey from '../Components/StripeKey';
import { loadStripe } from '@stripe/stripe-js';


const OrganizerRegistration = ({ onClose,isTesting=true }) => {
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [bandName, setBandName] = useState('');
  const [musicGenre, setMusicGenre] = useState('');
  const [gigRadius, setGigRadius] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [subscription1, setRadioOption1] = useState(true); // default
  const [subscription2, setRadioOption2] = useState(false);
  const [discount, setDiscount] = useState('');
  const [billingFirstName, setBillingFirstName] = useState('');
  const [billingLastName, setBillingLastName] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [billingPhone, setBillingPhone] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [billingZipCode, setBillingZipCode] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingState, setBillingState] = useState('');
  const [billingCountry, setBillingCountry] = useState('US');
  const [contactInformation, setContactInfo] = useState('This variable is depreciated and will be removed in the future, contact info is redundant');
  const [countyInfo, setCounty] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTwoFactorModalVisible, setIsTwoFactorModalVisible] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [userId, setUserId] = useState(null);
  const [subscriptionAmount, setSubscriptionAmount] = useState(999); // default
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [cardDetails, setCardDetails] = useState(null);

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [isProcessing,Processing] = useState(false);
  const [infoFilled,setDetailsFilled] = useState(false);
  const [subscription,SetSubscription] = useState('Organizer Subscription 1'); //default
  const [storedpaymentIntent, SetStoredPaymentIntent] = useState('');
  

  const stripePromise = loadStripe(stripeKey);

  useEffect(() => {
    console.log("Updated username:", username);
  }, [username]);

  const resetForm = () => {
    setSelectedCountry('US');
    setUsername('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setWebsite('');
    setPassword('');
    setRepeatPassword('');
    setBandName('');
    setMusicGenre('');
    setGigRadius('');
    setStreetAddress('');
    setZipCode('');
    setCity('');
    setState('');
    setPhoneNumber('');
    setRadioOption1(true); // reset to default subscription
    setRadioOption2(false);
    setDiscount('');
    setBillingFirstName('');
    setBillingLastName('');
    setBillingEmail('');
    setBillingPhone('');
    setBillingAddress('');
    setBillingZipCode('');
    setBillingCity('');
    setBillingState('');
    setBillingCountry('US');
    setContactInfo('');
    setCounty('');
    setIsModalVisible(false);
    setIsTwoFactorModalVisible(false);
    setTwoFactorCode('');
    setUserId(null);
    setSubscriptionAmount(999); // reset to default amount
    setPaymentConfirmed(false);
    setCardDetails(null);
    setPaymentSuccess(false);
    setPaymentError(null);
    Processing(false);
    setDetailsFilled(false);
  };

  const handleCustomerIdGenerated = (id,paymentIntentId) => {
    //setCustomerId(id);
    console.log('Customer ID Organizer Registration Generated:', id);
    registerOrganizer(id,paymentIntentId);
  };
  

  const cardPayload = {
    username,
    firstName,
    lastName,
    email,
    password,
    repeatPassword,
    streetAddress,
    zipCode,
    city,
    state,
    website,
    phoneNumber,
    billingFirstName,
    billingLastName,
    billingEmail,
    billingPhone,
    billingAddress,
    billingZipCode,
    billingCity,
    billingState,
    billingCountry,
    subscription,
};

  const checkPayload = (payload) => {
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const value = payload[key];

        if (!username.trim()) {
          alert("Please enter a username.");
          console.log("Username:"+username);
          return; // Early return to prevent submission
        }
        
        // Check if the value is a string and is empty after trimming
        if (typeof value === 'string' && value.trim() === '') {
          console.log(`Missing or empty field: ${key}`);
          return false; // If any field is an empty string, return false
        }

        
  
        // Check if the value is null or undefined
        if (value === null || value === undefined) {
          console.log(`Missing field: ${key}`);
          return false; // If any field is null or undefined, return false
        }
      }
    }
    return true; // All fields are present and filled, return true
  };
  

  const handlePaymentSuccess = () => {
    setPaymentSuccess(true);
    // Additional logic after successful payment (e.g., navigate to next screen)
  };

  const handlePaymentFailure = (errorMessage) => {
    setPaymentError(errorMessage);
    // Additional logic on payment failure (e.g., display error message)
  };

  const handleRadio1Press = () => {
    setRadioOption1(true);
    setRadioOption2(false);
    setSubscriptionAmount(10000); // Amount in cents ($100.00)
  };

  const handleRadio2Press = () => {
    setRadioOption2(true);
    setRadioOption1(false);
    setSubscriptionAmount(999); // Amount in cents ($9.99)
  };

  const copyPersonalToBilling = () => {
    setBillingFirstName(firstName);
    setBillingLastName(lastName);
    setBillingEmail(email);
    setBillingPhone(phoneNumber);
    setBillingAddress(streetAddress);
    setBillingZipCode(zipCode);
    setBillingCity(city);
    setBillingState(state);
    setBillingCountry(selectedCountry);
  };

  

  const registerOrganizer = async (Stripe_CustomerID,paymentIntentId) => {

    setContactInfo('This variable has been depreciated and will be removed in the future it is redundant');
   
    if (subscription1) {
      SetSubscription("Organizer Subscription 1");
    } else if (subscription2) {
        SetSubscription("Organizer Subscription 2");
    } else {
        // Handle case where no subscription is selected
        return;
    }
    console.log("Subscription Set:", subscription);

    
    console.log("Stripe Customer ID"+Stripe_CustomerID);

    const organizerData = {
        username,
        firstName,
        lastName,
        email,
        password,
        repeatPassword,
        streetAddress,
        zipCode,
        city,
        state,
        website,
        phoneNumber,
        billingFirstName,
        billingLastName,
        billingEmail,
        billingPhone,
        billingAddress,
        billingZipCode,
        billingCity,
        billingState,
        billingCountry,
        subscription,
        Stripe_CustomerID
    };

    console.log("Payload: "+JSON.stringify(organizerData));

    try {
      const response = await axios.post('http://34.232.96.254/OrganizerRegister.php', organizerData);
  console.log("RESPONSE: "+JSON.stringify(response));
      if (response.data.success) { // Adjusted condition
          console.log('Registration successful:', response.data.success);
          window.alert("Registration Successful. Payment Confirmed");
          resetForm();
      } else {
          console.log('Registration failed:', response.data.message);
          window.alert("Registration failed: " + response.data.message);
          refundPayment(Stripe_CustomerID,paymentIntentId);
          resetForm();
      }
  } catch (error) {
      console.error('Error registering organizer:', error);
      window.alert("An error occurred during registration. Please try again.");
      resetForm();
  } finally {
      Processing(false); // This will always run regardless of success or failure
  }
  
  
};

const refundPayment = async (customerId, paymentIntentId) => {
  try {
    const response = await fetch('http://34.232.96.254/RefundPayment.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customerId,
        paymentIntentId
      }),
    });

    const data = await response.json();
    
    if (data.success) {
      console.log('Refund successful:', data.refund);
    } else {
      console.error('Refund failed:', data.error);
    }
  } catch (error) {
    console.error('Error during refund process:', error);
  }
};


function extractSuccess(response) {
  try {
      // Extract the JSON part from the response data, ignoring any notices or extra text
      const jsonString = response.data.match(/\{.*\}/s)[0];
      
      // Parse the JSON string
      const jsonResponse = JSON.parse(jsonString);
      
      // Return the success field
      return jsonResponse.success;
  } catch (error) {
      // Handle errors (e.g., malformed JSON, no match found)
      console.error("Failed to extract success status:", error);
      return null;
  }
}


  const billingDetails = {
    name: billingFirstName+" "+billingLastName, // Replace with your logic
    email: billingEmail,
    phone: billingPhone,
    address: {
      city: billingCity,
      country: billingCountry,
      line1: billingAddress,
      line2: '',
      postal_code: billingZipCode,
    },
  };

  const  PriceSelected =()=>{

//let isTesting=true; // in debug

if(isTesting==true)
{

    if(subscription1==true)
       {
      return 'price_1PipgyADRnUTSqmNP3sE0SJA'; // 100 yearly
       }

    if(subscription2==true)
    {
       return 'price_1PipjDADRnUTSqmNQeLLGV6A'; //9.99 monthly
    }

}

if(isTesting==false) // REAL MONEY 
{

  if(subscription1==true)
    {
   return 'price_1PmIHaADRnUTSqmN1CJvcMS3'; // 100 yearly
    }

 if(subscription2==true)
 {
    return 'price_1PmII9ADRnUTSqmNWw9Hhf9D'; //9.99 monthly
 }
}


 };

 

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Organizer Registration</h2>

      {/* Personal Information */}
      <h3 style={styles.sectionTitle}>Personal Information</h3>
      <div style={styles.formContainer}>
        <div style={styles.inputContainer}>
          <label>Username</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)}  />
        </div>
        <div style={styles.inputContainer}>
          <label>First Name</label>
          <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Last Name</label>
          <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </div>       
        <div style={styles.inputContainer}>
          <label>E-mail</label>
          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Website</label>
          <input type="text" value={website} onChange={(e) => setWebsite(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Password</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Repeat Password </label>
          <input type="password" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Phone Number</label>
          <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
        </div>

        
        {/* Address */}
        <h3 style={styles.sectionTitle}>Address</h3>
        <div style={styles.inputContainer}>
          <label>Street Address</label>
          <input type="text" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Zip Code</label>
          <input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>City</label>
          <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>State</label>
          <input type="text" value={state} onChange={(e) => setState(e.target.value)} />
        </div>

        {/* Country */}
        <div style={styles.inputContainer}>
          <label>Country</label>
          <select value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
            <option value="">Select a country</option>
            {countriesArray.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        <button style={styles.button} onClick={copyPersonalToBilling}>
        Copy Personal to Billing
      </button>

        {/* Billing Details */}
        <h3 style={styles.sectionTitle}>Billing Details</h3>
        <div style={styles.inputContainer}>
          <label>Billing First Name</label>
          <input type="text" value={billingFirstName} onChange={(e) => setBillingFirstName(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Last Name</label>
          <input type="text" value={billingLastName} onChange={(e) => setBillingLastName(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Email</label>
          <input type="text" value={billingEmail} onChange={(e) => setBillingEmail(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Phone</label>
          <input type="text" value={billingPhone} onChange={(e) => setBillingPhone(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Address</label>
          <input type="text" value={billingAddress} onChange={(e) => setBillingAddress(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Zip Code</label>
          <input type="text" value={billingZipCode} onChange={(e) => setBillingZipCode(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing City</label>
          <input type="text" value={billingCity} onChange={(e) => setBillingCity(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing State</label>
          <input type="text" value={billingState} onChange={(e) => setBillingState(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Country</label>
          <select value={billingCountry} onChange={(e) => setBillingCountry(e.target.value)}>
            <option value="">Select a country</option>
            {countriesArray.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        {/* Subscription */}
        <h3 style={styles.sectionTitle}>Subscription</h3>
        <div style={styles.radioContainer}>
          <input type="radio" id="subscription1" name="subscription" checked={subscription1} onChange={handleRadio1Press} />
          <label htmlFor="subscription1">Organizer - Yearly - $100.00 / 12 Months and a $100.00 sign-up fee Yearly Subscriptions for Organizers at the rate of 100 per year</label>
        </div>
        <div style={styles.radioContainer}>
          <input type="radio" id="subscription2" name="subscription" checked={subscription2} onChange={handleRadio2Press} />
          <label htmlFor="subscription2">Organizer - Monthly - $9.99 / 1 Month and a $9.99 sign-up fee Monthly subscriptions for Organizers only at the rate of 9.99 dollar per month</label>
        </div>

        
        <Elements stripe={stripePromise}>
      <PaymentScreen
      onPaymentSuccess={() => {
        // Payment was successful; handle customer ID generation here
        // This should eventually trigger `handleCustomerIdGenerated`
    }}
      onPaymentFailure={handlePaymentFailure}
      customerInfo={billingDetails}
      priceId={PriceSelected()} // Replace with your actual price ID from Stripe
      isAccountDetails={false}
      EmailVerify={billingDetails.email}
      Processing={Processing}
      isProcessing={isProcessing}
      InputDetails={cardPayload}
      isOrganizerRegistration={true}
      onCustomerIdGenerated={handleCustomerIdGenerated}
      checkPayload={checkPayload}
     
    />
</Elements>
<div style={styles.bottomContainer}></div>
      </div>

     
     

      
    </div>
  );
};
const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', // Center items horizontally
      justifyContent: 'flex-start', // Align items to the start vertically
      margin: '20px',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#f9f9f9',
      maxWidth: '600px',
      height:'2350px',
      
    },
    title: {
      fontSize: '1.5rem',
      marginBottom: '20px',
    },
    sectionTitle: {
      fontSize: '1.2rem',
      marginTop: '20px',
      marginBottom: '10px',
    },
    formContainer: {
      width: '100%',
      maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', // Align inputs to the left
    },
    inputContainer: {
      marginBottom: '10px',
      display: 'flex',
      flexDirection: 'column', // Ensure each input container is a column
    },
    label: {
      marginBottom: '5px', // Add margin below each label for spacing
    },
    registerButton: {
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      padding: '10px 20px',
      cursor: 'pointer',
      marginTop: '20px',
    },
    
    bottomContainer:{

      paddingBottom:'100px',
    }
  };
  

export default OrganizerRegistration;

